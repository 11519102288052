import { Container } from '@material-ui/core'
import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import styled from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'

const VideoItems = [
  '903344399',
  '903340727',
  '903339212',
  '903337559',
  '903337240',
  '900695466',
  '900693158',
  '900692371',
  '900690941',
  '900690423',
  '900689003',
]

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const VideoPage = ({ data }) => (
  <>
    <SEO title="Kultura Regionalna" />
    <Container style={{ marginTop: 30 }}>
      <GridWrapper>
        {VideoItems.map(el => (
          <Vimeo video={el} responsive />
        ))}
      </GridWrapper>
    </Container>
  </>
)

// export const query = graphql`
//   {
//     mapaniepodlegla: file(
//       relativePath: { regex: "/universal/" }
//       name: { regex: "/niepodleglamapa/" }
//     ) {
//       childImageSharp {
//         fluid(maxWidth: 1200, quality: 100) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

export default VideoPage
